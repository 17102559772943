import React, { Component } from 'react';
import { PayInvoiceDetails } from './payinvoicedetails';

export class PayInvoice extends Component {

    render() {
        return (
            <div className='payinvoiceheader bg-light'>
                <PayInvoiceDetails />
            </div>          
        );
    }


}
