export const appsettings = {
    routes : {
      CreditCards_URL: (window.location.href.toString().indexOf("wpayments") !== -1 ? "https://apiwest.aims360.rest/customers" : "https://apieast.aims360.rest/customers"),
      PayInvoice_Base_URL: (window.location.href.toString().indexOf("wpayments") !== -1 ? "https://aims360-fn-w-invoicemanagement.azurewebsites.net" : "https://aims360-fn-e-invoicemanagement.azurewebsites.net")
    },
    constants : {
      invoice_base_url_version :("v1.0"),
      invoice_base_param1: ("/invoice/"),
      invoice_base_param2 : ("/payinvoice/")
  }
}

// CreditCards_URL: (window.location.href.toString().indexOf("wpayments") !== -1 ? "https://apiwest.aims360.rest/customers" : "https://apieast.aims360.rest/customers"),
// PayInvoice_Base_URL: (window.location.href.toString().indexOf("wpayments") !== -1 ? "https://aims360-fn-w-invoicemanagement.azurewebsites.net" : "https://aims360-fn-e-invoicemanagement.azurewebsites.net")

//Old: PayInvoice_Base_URL: (window.location.href.toString().indexOf("wpayments") !== -1 ? "https://apiwest.aims360.rest/invoices" : "https://apieast.aims360.rest/invoices")
// CreditCards_URL: "https://aims360-fn-customermanagementtest.azurewebsites.net"
// CreditCards_URL: "https://aims360-fn-customermanagementtest-team5.azurewebsites.net"
// CreditCards_URL: "http://localhost:7084"
// PayInvoice_Base_URL: "http://localhost:7089"
// PayInvoice_Base_URL: "https://aims360-fn-invoicetest.azurewebsites.net"
//https://wpayments.aims360.rest
//https://epayments.aims360.rest

