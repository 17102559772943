import React, { Component } from 'react';
import "./spinner.css";

export class LoadingSpinner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderText: ""
        }
    }

    render() {
        return (
            <div className="spinner-container">
                <div className="loading-spinner"></div>
                <span className="loader-text">{this.props.loaderText}</span>
            </div>
        )
    }
}