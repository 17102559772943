import {CCList} from './components/customer/cclist';
import {PayInvoice} from './components/Invoice/payinvoice';
import {PaymentReceipt} from './components/Invoice/paymentreceipt';
import './App.css';


function App() {
  const currentUrl = window.location.href;
  const isPayInvoicePage = currentUrl.includes('payinvoice');
  const isReceiptPage = currentUrl.includes('paymentreceipt');
   return (
     <div className="App">
      {isReceiptPage ? <PaymentReceipt /> : (isPayInvoicePage ? <PayInvoice /> : <CCList />)}
    </div>
   );
}

export default App;
