export const common = {
    getCardImage: function(cardType){
        switch(cardType.toString().toUpperCase()) {
            case "AMEX":
            case "AMERICANEXPRESS":
                return "/images/amax.png";
                break;
            case "DINERSCLUB":
                return "/images/dinersclub.png";
              break;
            case "DISCOVER":
              return "/images/discover.png";
              break;
            case "ENROUTE":
              return "/images/enroute.png";
              break;
            case "JCB":
              return "/images/jcb.png";
              break;
            case "MASTERCARD":
              return "/images/mastercard.png";
              break;
            case "VISA":
              return "/images/visa.png";
              break;
            default:
              return "";
              break;
          }
    },
    setSessionItem: function (key, stringValue){
      sessionStorage.setItem(key, stringValue);
    },
    getSessionItem: function (key){
      return sessionStorage.getItem(key);
    }
}