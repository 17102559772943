import React, { Component } from 'react';
import { appsettings } from '../../appsettings.js';
import { common } from '../common/common.js';
import { LoadingSpinner } from '../common/spinner.js';
import './cc.css';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import logo from '../../images/aimslogotrans.png';
export class CCList extends Component {
    constructor(props) {
        super(props);

        this.customerID = "";
        this.tokenInfo = "";
        this.emailParms = "";
        this.state = {
            isLoading:false,
            loaderText: "",
            validRequest: true,
            customer: {},
            supportedCardTypes:[],
            supportedCardTypeImages:[],
            countryList: [],
            creditCards: [],
            modalTitle: "",
            showLogo: true,
            showLogoAddress: false,
            companyLogo: "",
            companyAddress: "",
            displayYears: 25,
            creditCardID: "",
            cardType: "",
            cardNumber: "",
            expiryMonth: -1,
            expiryYear: -1,
            defaultCard: true,
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
            countryCode: "",
            countryName: "",
            countryID: "",
            cardCode: "",
            showAIMSAlert: false,
            aimsAlertText: "",
            aimsAlertHTML: "",
            aimsAlertHeaderClass: "bg-danger text-white aimsAlertHeader",
            confirmationCallBackMethod:"",
            showConfirmationModal:false,
            confirmationModalText: "",
            currentCCID: 0
        }
        this.enforceMaxOnCardNumber = this.enforceMaxOnCardNumber.bind(this);
        this.enforceMaxOnCardCode = this.enforceMaxOnCardCode.bind(this);  
    }

    changeCardNumber = (e) => {
        this.setState({ cardNumber: e.target.value });
    }
    changeExpiryMonth = (e) => {
        this.setState({ expiryMonth: e.target.value });
    }
    changeExpiryYear = (e) => {
        this.setState({ expiryYear: e.target.value });
    }
    changeDefaultCard = (e) => {
        this.setState({ defaultCard: e.target.value });
    }
    changeFirstName = (e) => {
        this.setState({ firstName: e.target.value });
    }
    changeLastName = (e) => {
        this.setState({ lastName: e.target.value });
    }
    changeAddress = (e) => {
        this.setState({ address: e.target.value });
    }
    changeCity = (e) => {
        this.setState({ city: e.target.value });
    }
    changeState = (e) => {
        this.setState({ state: e.target.value });
    }
    changeZipCode = (e) => {
        this.setState({ zipCode: e.target.value });
    }
    changeCountry = (e) => {
        let obj = this.state.countryList.find(c => c.countryID == e.target.value);
        this.setState({ countryID: e.target.value });
        this.setState({ countryCode: obj.countryCode });
        this.setState({ countryName: obj.countryName });
    }
    changeCardCode = (e) => {
        this.setState({ cardCode: e.target.value });
    }

    showLoader(showLoader, LoaderText){
        this.setState({ isLoading: showLoader });
        this.setState({ loaderText: LoaderText });
    }
    
    componentDidMount() {
        var url = window.location.href;
        var strCustomerID =  "";
        var strAdditionalInfo = "";
        if (url.indexOf("customer/") > 0 && url.indexOf("/requestpaymentdetails") > 0)
        {
            strCustomerID = url.substring(url.indexOf("customer/") + 9, url.indexOf("/requestpaymentdetails"));
            this.customerID= strCustomerID;
        }
        if (url.indexOf("details/") > 0)
        {
            strAdditionalInfo = url.substring(url.indexOf("details/") + 8);
            this.tokenInfo= strAdditionalInfo.split("/")[0];
        }
        //this.emailParms= strAdditionalInfo.split("/")[1];
        
        if ((this.customerID == undefined || this.customerID == "") || (this.tokenInfo == undefined || this.tokenInfo == ""))
        {
            this.setState({ validRequest: false });
            this.AIMSAlert("Attention Required!", "Invalid request. Please use the same url received to the email.", false);
            return;
        }

        var cTypes = common.getSessionItem("SupportedCardTypes");
        if (cTypes == null)
        {
            this.fetchCustomerSupportedCardTypes();
        }
        else {
            cTypes = JSON.parse(cTypes);
            this.bindSupportedCardTypes(cTypes.supportedCardTypes);
        }
        this.fetchCountries();
        this.refreshList(); 
    }
    bindSupportedCardTypes(data) {
        if (data != undefined && data.length > 0)
        {
            let imgArray = new Array();
            for (let i=0;i<data.length;i++) {
                let imgSrc = common.getCardImage(data[i]);
                if (imgSrc != "")
                imgArray.push(imgSrc);
            }
            this.setState({ supportedCardTypes: data });
            this.setState({ supportedCardTypeImages: imgArray });
            common.setSessionItem("SupportedCardTypes", JSON.stringify({ supportedCardTypes: data }));
        }
    }
    fetchCustomerSupportedCardTypes() {
        fetch(appsettings.routes.CreditCards_URL + '/v1.0/customer/' + this.customerID + '/requestpaymentdetails/merchantsupportedcardtypes/' + this.tokenInfo + '/0', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data != undefined && data.length > 0)
            {
                this.bindSupportedCardTypes(data);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
    fetchCountries() {
        fetch(appsettings.routes.CreditCards_URL + '/v1.0/customer/' + this.customerID + '/country/' + this.tokenInfo, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data != undefined)
            {
                if (data.ErrorMessage != null && data.ErrorMessage != undefined && data.ErrorMessage != "")
                {
                    this.AIMSAlert("Attention Required!", data.ErrorMessage, false);
                    this.setState({ validRequest: false });
                }
                else
                {
                    this.setState({ countryList: data });
                }                
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
    refreshList() {
        this.showLoader(true,"Fetching details.. Please wait..." );
        fetch(appsettings.routes.CreditCards_URL + '/v1.0/customer/' + this.customerID + '/requestpaymentdetails/' + this.tokenInfo, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            this.showLoader(false,"" );
            if (data != undefined)
            {
                if (data.ErrorMessage != null && data.ErrorMessage != undefined && data.ErrorMessage != "")
                {
                    this.AIMSAlert("Attention Required!", data.ErrorMessage, false);
                    this.setState({ validRequest: false });
                }
                else
                {
                    if (data.Customer != null)
                    {
                        this.setState({ customer: data.Customer });
                    }
                    if (data.DisplayLogoType == "LOGO")
                    {
                        this.setState({ showLogo: true });
                        this.setState({ showLogoAddress: false });
                        this.setState({ companyLogo: data.ImageSource });
                    }
                    else if (data.DisplayLogoType == "ADDRESS")
                    {
                        this.setState({ showLogo: false });
                        this.setState({ showLogoAddress: true });
                        this.setState({ companyAddress: data.CompanyAddress });
                    }
    
                    var ccList = new Array();
                    var cc= data.CC;
                    if (cc != undefined)
                    {
                        for (let i = 0; i < cc.length; i++)
                        {
                            let ccobj = {
                                creditCardID : cc[i].creditCardID,
                                cardType: cc[i].cardType,
                                cardNumber : cc[i].cardNumber,
                                expiryMonth : cc[i].expiryMonth,
                                expiryYear : cc[i].expiryYear,
                                defaultCard : cc[i].defaultCard,
                                firstName : cc[i].firstName,
                                lastName : cc[i].lastName,
                                address : cc[i].billingAddress.address,
                                city : cc[i].billingAddress.city,
                                state : cc[i].billingAddress.state,
                                zipCode : cc[i].billingAddress.zipCode,
                                countryID : cc[i].billingAddress.country != null ? cc[i].billingAddress.country.countryID : "",
                                countryCode : cc[i].billingAddress.country != null ? cc[i].billingAddress.country.countryCode: "",
                                countryName : cc[i].billingAddress.country != null ? cc[i].billingAddress.country.countryName: "",
                                cardCode : ""
                            };
                            ccList.push(ccobj);
                        }
                    }
                    this.setState({ creditCards: ccList });
                }
            }
        })
        .catch(error => {
            this.showLoader(false,"" );
        });
    }
    addClick() {
        this.setState({
            modalTitle: "Add Card",
            creditCardID: "",
            cardType: "",
            cardNumber: "",
            expiryMonth: -1,
            expiryYear: -1,
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
            countryID : "",
            countryCode : "",
            countryName : "",
            cardCode: ""
        });
    }
    editClick(cc) {
        this.setState({
            modalTitle: "Edit Card",
            creditCardID: cc.creditCardID,
            cardType: cc.cardType,
            cardNumber: cc.cardNumber,
            expiryMonth: cc.expiryMonth,
            expiryYear: cc.expiryYear,
            defaultCard: cc.defaultCard,
            firstName: cc.firstName,
            lastName: cc.lastName,
            address: cc.address,
            city: cc.city,
            state: cc.state,
            zipCode: cc.zipCode,
            countryID : cc.countryID,
            countryCode : cc.countryCode,
            countryName : cc.countryName,
            cardCode: cc.cardCode
        });
    }
    createCC() {
        let valMsg = "";
        if (this.state.cardNumber == "")
            valMsg += "Invalid Card Number <br />";
        if (this.state.expiryMonth == "" || this.state.expiryMonth == "-1")
            valMsg += "Invalid Expiry Month <br />";
        if (this.state.expiryYear == "" || this.state.expiryYear == "-1")
            valMsg += "Invalid Expiry Year <br />";
        if (this.state.firstName == "")
            valMsg += "Invalid First Name <br />";
        // if (this.state.lastName == "")
        //     valMsg += "Invalid Last Name <br />";
        // if (this.state.address == "")
        //     valMsg += "Invalid Address <br />";
        // if (this.state.city == "")
        //     valMsg += "Invalid City <br />";
        // if (this.state.state == "")
        //     valMsg += "Invalid State <br />";
        // if (this.state.zipCode == "")
        //     valMsg += "Invalid Zip Code <br />";
        // if (this.state.countryID == "")
        //     valMsg += "Invalid Country";

        if (valMsg != "")
        {
            this.AIMSAlert("Attention Required!", valMsg, false);
            //alert(valMsg);
            return;
        }
        if (this.state.creditCards.length == 0){
            this.setState({ confirmationCallBackMethod: "saveCreditCard" });
            this.setState({ confirmationModalText: "Please confirm if you would like to update all of your current open orders that are set for credit card terms (CR) to use this card.<br />NOTE: This will not affect any current pre-payments or pre-authorizations for existing orders in your account. This update will only affect new authorizations or charges going forward." });
            this.setState({ showConfirmationModal: true });
        }
        else
        {
            this.createCCCallback(false);
        }
    }
    createCCCallback(updateExistingOrder) {
        
        var createCCObj = {
            creditCardID: this.state.creditCardID,
            cardNumber: this.state.cardNumber.substring(0, 16),
            expiryMonth: Number(this.state.expiryMonth),
            expiryYear: Number(this.state.expiryYear),
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            billingAddress: {
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                zipCode: this.state.zipCode
            }
        };
        if (this.state.countryID != "")
        {
            createCCObj.billingAddress.country = {
                countryID : this.state.countryID,
                countryCode : this.state.countryCode,
                countryName : this.state.countryName
            }
        }

        if (this.state.cardCode != ""){
            createCCObj.cvv = this.state.cardCode.substring(0, 4);
        }
        
        if (this.state.creditCards.length == 0){
            createCCObj.defaultCard = true;
            createCCObj.updateExistingOrder = updateExistingOrder;
        }
        
        this.showLoader(true,"Saving credit card.. Please wait..." );
        fetch(appsettings.routes.CreditCards_URL + '/V1.0/customer/' + this.customerID + '/requestpaymentdetails/' + this.tokenInfo, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(createCCObj)
        })
        .then(res => res.json())
        .then((result) => {
            this.showLoader(false,"" );
            if (result.ErrorMessage != null && result.ErrorMessage != undefined && result.ErrorMessage != "")
                this.AIMSAlert("Attention Required!", "Failed to save. " + result.ErrorMessage, false);
            else
            {
                document.getElementById("btnmodalclose").click();
                this.refreshList();
            }
        }, (error) => {
            this.showLoader(false,"" );
            this.AIMSAlert("Attention Required!", 'Failed to save. ' + error, false);
        })
    }
    updateCC() { 
        let valMsg = "";       
        if (this.state.expiryMonth == "" || this.state.expiryMonth == "-1")
            valMsg += "Invalid Expiry Month <br />";
        if (this.state.expiryYear == "" || this.state.expiryYear == "-1")
            valMsg += "Invalid Expiry Year <br />";
        if (this.state.firstName == "")
            valMsg += "Invalid First Name <br />";
        // if (this.state.lastName == "")
        //     valMsg += "Invalid Last Name <br />";
        // if (this.state.address == "")
        //     valMsg += "Invalid Address <br />";
        // if (this.state.city == "")
        //     valMsg += "Invalid City <br />";
        // if (this.state.state == "")
        //     valMsg += "Invalid State <br />";
        // if (this.state.zipCode == "")
        //     valMsg += "Invalid Zip Code <br />";
        // if (this.state.countryID == "")
        //     valMsg += "Invalid Country";

        if (valMsg != "")
        {
            this.AIMSAlert("Attention Required!", valMsg, false);
            return;
        }
        var updateCCObj = {
            creditCardID: this.state.creditCardID,
            cardType: this.state.cardType,
            cardNumber: this.state.cardNumber.substring(0, 16),
            expiryMonth: Number(this.state.expiryMonth),
            expiryYear: Number(this.state.expiryYear),
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            billingAddress: {
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                zipCode: this.state.zipCode
            }
        };
        if (this.state.countryID != "")
        {
            updateCCObj.billingAddress.country = {
                countryID : this.state.countryID,
                countryCode : this.state.countryCode,
                countryName : this.state.countryName
            }
        }

        if (this.state.cardCode != ""){
            updateCCObj.cvv = this.state.cardCode.substring(0, 4);
        }
        this.showLoader(true,"Updating credit card.. Please wait..." );
        fetch(appsettings.routes.CreditCards_URL + '/V1.0/customer/' + this.customerID + '/requestpaymentdetails/' + updateCCObj.creditCardID + '/' + this.tokenInfo, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updateCCObj)
        })
        .then(res => res.json())
        .then((result) => {
            this.showLoader(false,"" );
            if (result.ErrorMessage != null && result.ErrorMessage != undefined && result.ErrorMessage != "")
                this.AIMSAlert("Attention Required!", "Failed to update. " + result.ErrorMessage, false);
            else
            {
                document.getElementById("btnmodalclose").click();
                this.refreshList();
            }
        }, (error) => {
            this.showLoader(false,"" );
            this.AIMSAlert("Attention Required!", 'Failed to update. ' + error, false);
        })
    }
    deleteCC(ccid) {
        this.setState({ currentCCID: ccid });
        this.setState({ confirmationCallBackMethod: "delete" });
        this.setState({ confirmationModalText: "Are you sure you want to delete?" });
        this.setState({ showConfirmationModal: true });
    }
    deleteCCCallBack() {
        
        let ccId = this.state.currentCCID;
        this.showLoader(true,"Deleting credit card.. Please wait..." );
        fetch(appsettings.routes.CreditCards_URL + '/V1.0/customer/' + this.customerID + '/requestpaymentdetails/' + ccId + '/' + this.tokenInfo, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then((result) => {
            this.showLoader(false,"" );
            if (result.ErrorMessage != null && result.ErrorMessage != undefined && result.ErrorMessage != "")
                this.AIMSAlert("Attention Required!", "Failed to delete. " + result.ErrorMessage, false);
            else
            {
                this.refreshList();
            }
        }, (error) => {
            this.showLoader(false,"" );
            this.AIMSAlert("Attention Required!", 'Failed to delete. ' + error, false);
        })
    }
    setAsDefaultCC(ccid) {
        
        this.setState({ currentCCID: ccid });
        this.setState({ confirmationCallBackMethod: "setasdefault" });
        this.setState({ confirmationModalText: "Please confirm if you would like to update all of your current open orders that are set for credit card terms (CR) to use this card.<br />NOTE: This will not affect any current pre-payments or pre-authorizations for existing orders in your account. This update will only affect new authorizations or charges going forward." });
        this.setState({ showConfirmationModal: true });
    }
    setAsDefaultCCCallBack(updateExistingOrder) {
        
        let ccId = this.state.currentCCID;
        this.showLoader(true,"Setting credit card as default.. Please wait..." );
        var objSetAsDefault = {
            updateExistingOrders: updateExistingOrder,
        };
        fetch(appsettings.routes.CreditCards_URL + '/V1.0/customer/' + this.customerID + '/requestpaymentdetails/setasdefault/' + ccId + '/' + this.tokenInfo, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(objSetAsDefault)
        })
        .then(res => res.json())
        .then((result) => {
            this.showLoader(false,"" );
            if (result.ErrorMessage != null && result.ErrorMessage != undefined && result.ErrorMessage != "")
                this.AIMSAlert("Attention Required!", "Failed to set card as default. " + result.ErrorMessage, false);
            else
            {
                this.refreshList();
            }
        }, (error) => {
            this.showLoader(false,"" );
            this.AIMSAlert("Attention Required!", 'Failed to set card as default. ' + error, false);
        })
    }
    enforceMaxOnCardNumber(el) {
        if (el.target.value != "") {
          if (parseInt(el.target.value) > parseInt(el.target.max)) {
            el.target.value = el.target.value.substring(0, 16);
            this.setState({ cardNumber: el.target.value });
          }
        }
    }
    enforceMaxOnCardCode(el) {
        if (el.target.value != "") {
          if (parseInt(el.target.value) > parseInt(el.target.max)) {
            el.target.value = el.target.value.substring(0, 4);
            this.setState({ cardCode: el.target.value });
          }
        }
    }
    AIMSAlert(headerText, bodyHTML, isSuccess) {
        this.setState({ showAIMSAlert: true });
        this.setState({ aimsAlertText: headerText });
        this.setState({ aimsAlertHTML: bodyHTML });
        if(isSuccess == true)
            this.setState({ aimsAlertHeaderClass: "bg-success text-white aimsAlertHeader" });        
        else
            this.setState({ aimsAlertHeaderClass: "bg-danger text-white aimsAlertHeader" });        
    }    
    confirmationYes = (e) => {
        this.setState({ showConfirmationModal: false });
        let callBackMethod = this.state.confirmationCallBackMethod;
        switch (callBackMethod) {
            case "delete":
                this.deleteCCCallBack();
                break;
            case "setasdefault":
                this.setAsDefaultCCCallBack(true);
                break;
            case "saveCreditCard":
                this.createCCCallback(true);
                break;
                
        }
    }
    confirmationNo = (e) => {
        this.setState({ showConfirmationModal: false });
        let callBackMethod = this.state.confirmationCallBackMethod;
        switch (callBackMethod) {
            case "setasdefault":
                this.setAsDefaultCCCallBack(false);
                break;
            case "saveCreditCard":
                this.createCCCallback(false);
                break;
        }
    }
    
    render() {
        const {
            isLoading,
            loaderText,
            countryList,
            supportedCardTypeImages,
            validRequest,
            showLogo,
            showLogoAddress,
            companyLogo,
            companyAddress,
            creditCards,
            modalTitle,
            months,
            displayYears,
            creditCardID,
            cardType,
            cardNumber,
            expiryMonth,
            expiryYear,
            defaultCard,
            firstName,
            lastName,
            address,
            city,
            state,
            zipCode,
            countryCode,
            countryName,
            countryID,
            cardCode,
            showAIMSAlert,
            aimsAlertText,
            aimsAlertHTML,
            aimsAlertHeaderClass,
            confirmationModalText,
            currentCCID,
            showConfirmationModal,
            customer
        } = this.state;

        let currentMonth = new Date().getMonth() + 1;
        let currentYear = new Date().getFullYear();
        let monthDDItems = [];
        let monthsName = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        monthDDItems.push(<option value="-1">Select Month...</option>);
        for (var i = 0; i < 12; i++) {
            monthDDItems.push(<option value={i+1}>{monthsName[i]}</option>);
        }

        let yearDDItems = [];
        yearDDItems.push(<option value="-1">Select Year...</option>);
        for (var i = 0; i < displayYears; i++) {
            yearDDItems.push(<option value={currentYear + i}>{currentYear + i}</option>);
        }

        return (
            <div>
                {isLoading ? <LoadingSpinner loaderText={loaderText} /> : ""}
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <div className="ccnavbarcontainer">
                            <div className="ccnavbarlogo">
                                <a href="#" className={"navbar-brand companylogocontainer " + (showLogo ? 'showelement' : 'hideelement')} id="ccPageLogo">
                                    <img src={companyLogo} className='companylogo' />
                                </a>
                                <div className={"navbar-brand companylogoaddress " + (showLogoAddress ? 'showelement' : 'hideelement')} dangerouslySetInnerHTML={{__html: companyAddress}}>
                                </div>
                            </div>
                            <div className={"ccnavbartitle " + (showLogoAddress ? 'ccnavbartitle-address' : '')} id="ccPageTitle">
                                Payment Methods { (customer != undefined && customer != null ?  (customer.Code != undefined ? "for " + customer.Code.toString().trim() + (customer.Name != undefined ? " - " + customer.Name.toString().trim() : "") : "") : "") }
                            </div>
                        </div>
                    </div>
                </nav>
                <div className='container'>
                    <div id="ccnolist" className={"py-3 " + (creditCards.length == 0 ? 'showelement' : 'hideelement')}>
                        <h5>No cards available</h5>
                    </div>
                    <div id="ccelementlist"  className={(creditCards.length > 0 ? 'showelement' : 'hideelement')}>
                        <div className='row cclist-table-hdr'>
                            <div className='col-7 cclist-table-heading'><span>Your saved cards</span></div>
                            <div className='col-2 cclist-table-heading'><span>Expires</span></div>
                            <div className='col-3 cclist-table-heading'><span>Default Card</span></div>
                        </div>
                        <div className="accordion">
                            {creditCards.map((cc, index) =>
                                <div className="accordion-item mb-2 cclist-item" key={cc.creditCardID}>
                                    <h2 className="accordion-header cclist-header" id={`panelsStayOpen-heading${index + 1}`}>
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse${index + 1}`} aria-expanded="false" aria-controls={`panelsStayOpen-collapse${index + 1}`}>
                                            <div className="container-fluid">
                                                <div className="row" key={index}>
                                                    <div className="col-md-1 col-2 cclist-header-block">
                                                        <img src={common.getCardImage(cc.cardType)} className="ccimg-header" />
                                                    </div>
                                                    <div className="col-md-6 col-5 cclist-header-block">
                                                        <span>{cc.cardNumber}</span>
                                                    </div>
                                                    <div className="col-2 cclist-header-block">
                                                        <span className={((cc.expiryYear < currentYear) || (cc.expiryYear == currentYear && cc.expiryMonth < currentMonth)) ? 'text-danger text-expirydate' : 'text-expirydate'}>{((cc.expiryYear < currentYear) || (cc.expiryYear == currentYear && cc.expiryMonth < currentMonth)) ? "Expired " : ""}{monthsName[Number(cc.expiryMonth) - 1]}/{cc.expiryYear}</span>
                                                    </div>
                                                    <div className="col-3 cclist-header-block">
                                                        <span className='text-defaultard'>{cc.defaultCard ? "Yes" : ""}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div id={`panelsStayOpen-collapse${index + 1}`} className="accordion-collapse collapse" aria-labelledby={`panelsStayOpen-heading${index + 1}`}>
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-md-6 text-start">
                                                    <h4>Name on card:</h4>
                                                    <p>{cc.firstName + " " + cc.lastName}</p>
                                                </div>
                                                <div className="col-md-6 text-start">
                                                    <h4 className="ps-2">Billing Address:</h4>
                                                    {
                                                       (cc.address != "" || cc.city != "" || cc.state != "" && cc.countryName != "" && cc.zipCode != "") ?
                                                        (<div className="ps-2">
                                                            <p className='mb-1'>{cc.address}</p>
                                                            <p className='mb-1'>{cc.city}</p>
                                                            <p className='mb-1'>{cc.state}</p>
                                                            <p className='mb-1'>{cc.countryName}</p>
                                                            <p className='mb-1'>{cc.zipCode}</p>
                                                        </div>)
                                                        : <div className="ps-2">No address details found on card</div>
                                                    }
                                                    <div>
                                                        <button type="button" className="btn btn-outline-primary m-2" data-bs-toggle="modal" data-bs-target="#ccDetailModal" onClick={() => this.editClick(cc)}>Edit</button>
                                                        <button type="button" className="btn btn-outline-danger m-2" onClick={() => this.deleteCC(cc.creditCardID)}>Remove</button>
                                                        <button type="button" className={"btn btn-outline-secondary m-2" + (cc.defaultCard ? ' d-none' : '')} onClick={() => this.setAsDefaultCC(cc.creditCardID)} >Set as default</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={"pt-3 " + (!validRequest ? 'hideelement' : '')}>
                        <h4 className='text-start'>Add a new card</h4>
                        <hr className='hr'></hr>
                        <div className='row'>
                            <div className="col-6">
                                <button type="button" className="btn btn-outline-primary m-1 float-start" data-bs-toggle="modal" data-bs-target="#ccDetailModal" onClick={() => this.addClick()}>
                                    Add New Card
                                </button>
                            </div>
                            <div className="col-6 text-end">
                                {supportedCardTypeImages.map((src, index) => <img src={src} className="ccimg-icon" />)}
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="ccDetailModal" data-keyboard="false" data-backdrop="static" data-bs-backdrop="static" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{modalTitle}</h5>
                                    <button type="button" id="btnmodalclose" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>

                                <div className="modal-body">
                                    <h4 className='text-start mb-2'>Card details</h4>
                                    <div className={"mb-2 row " + (creditCardID != "" ? 'hideelement' : '')}>
                                        <label htmlFor="supportedlogosblock" className="col-sm-3 col-form-label text-end">Supported Cards:{creditCardID}</label>
                                        <div className="col-sm-9 text-start" id="supportedlogosblock">
                                            {supportedCardTypeImages.map((src, index) => <img src={src} className="ccimg-icon" />)}
                                        </div>
                                    </div>
                                    <div className="mb-2 row">
                                        <label htmlFor="txtCardNumber" className="col-sm-3 col-form-label text-end">Card Number: </label>
                                        <div className={(creditCardID != "" ? 'col-sm-8' : 'col-sm-9')}>
                                            <input type={( creditCardID != "" ? 'text' : 'number')} className="form-control" id="txtCardNumber" max="9999999999999999" onKeyUp={this.enforceMaxOnCardNumber} disabled = {(creditCardID != "")? "disabled" : ""} value={cardNumber} onChange={this.changeCardNumber} />
                                        </div>
                                        <div className={(creditCardID != "" ? 'col-sm-1 ps-0' : 'hideelement')}>
                                            <img src={common.getCardImage(cardType)} className="ccimg-icon" />
                                        </div>
                                    </div>
                                    <div className="mb-2 row">
                                        <label htmlFor="txtFirstName" className="col-sm-3 col-form-label text-end">First Name: </label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" id="txtFirstName" maxLength="40" value={firstName} onChange={this.changeFirstName} />
                                        </div>
                                    </div>
                                    <div className="mb-2 row">
                                        <label htmlFor="txtLastName" className="col-sm-3 col-form-label text-end">Last Name: </label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" id="txtLastName" maxLength="40" value={lastName} onChange={this.changeLastName} />
                                        </div>
                                    </div>
                                    <div className="mb-2 row">
                                        <label htmlFor="cmbExpiryMonth" className="col-sm-3 col-form-label  text-end">Expiry Date: </label>
                                        <div className="col-sm-3">
                                            <select className="form-select" id="cmbExpiryMonth" defaultValue={'-1'} value={expiryMonth} onChange={this.changeExpiryMonth}>
                                                {monthDDItems}
                                            </select>
                                        </div>
                                        <div className="col-sm-3">
                                            <select className="form-select" id="cmbExpiryYear" defaultValue={'-1'} value={expiryYear} onChange={this.changeExpiryYear}>
                                                {yearDDItems}
                                            </select>
                                        </div>
                                        <label htmlFor="txtCVV" className="col-sm-1 col-form-label  text-end">CVV: </label>
                                        <div className="col-sm-2">
                                            <input max="9999" type="number" className="form-control" id="txtCVV" value={cardCode} onChange={this.changeCardCode} onKeyUp={this.enforceMaxOnCardCode} />
                                        </div>
                                    </div>
                                    <h4 className='text-start mb-2'>Billing Address</h4>
                                    <div className="mb-2 row">
                                        <label htmlFor="txtAddress" className="col-sm-3 col-form-label  text-end">Address: </label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" id="txtAddress" maxLength="60" value={address} onChange={this.changeAddress} />
                                        </div>
                                    </div>
                                    <div className="mb-2 row">
                                        <label htmlFor="txtCity" className="col-sm-3 col-form-label  text-end">City/State/Zip: </label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="txtCity" maxLength="40" value={city} onChange={this.changeCity} />
                                        </div>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="txtState" maxLength="40" value={state} onChange={this.changeState} />
                                        </div>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="txtZip" maxLength="20" value={zipCode} onChange={this.changeZipCode} />
                                        </div>
                                    </div>
                                    <div className="mb-2 row">
                                        <label htmlFor="txtCountry" className="col-sm-3 col-form-label  text-end">Country/Country Code: </label>
                                        <div className="col-sm-6">
                                            <select className="form-select" id="txtCountry" defaultValue={'-1'} value={countryID} onChange={this.changeCountry}>
                                                <option value="">Select Country...</option>
                                                {countryList.map((con, index) => <option key={con.countryID} value={con.countryID}>{con.countryName}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="txtCountryCode" value={countryCode} />
                                        </div>
                                    </div>
                                    <div className="ccmodalbuttons">
                                        <button type="button" className="btn btn-outline-success float-center" onClick={() =>  creditCardID != "" ? this.updateCC() : this.createCC() }>Save</button>
                                        <button type="button" className="btn btn-outline-secondary float-center" data-bs-dismiss="modal" >Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className='footer'>
                    <div className="footercopyright">Secured by <a href="https://aims360.com/" target="_blank">AIMS360</a> & <a href="https://www.authorize.net/"  target="_blank">Authorize.net</a></div>
                </footer>
                {/* <ToastContainer position="middle-center" className={"p-2 " + (showAIMSAlert ? 'showelement' : 'hideelement')} style={{
                            position: 'fixed', 
                            top: '0',
                            left: '0',
			                width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(128,128,128,0.5)'
                            }}> */}
                    {/* <Toast onClose={() => this.setState({ showAIMSAlert: false })} show={showAIMSAlert} delay={100000} autohide>
                        <Toast.Header className={(aimsAlertHeaderClass)}>
                            <strong className="mr-auto" id="toastheadercontext" style={{width:"300px"}}>{aimsAlertText}</strong>
                        </Toast.Header>
                        <Toast.Body dangerouslySetInnerHTML={{__html: aimsAlertHTML}} style={{textAlign:"left"}}></Toast.Body>
                    </Toast> */}
                    <div className={"p-2 " + (showAIMSAlert ? 'showelement' : 'hideelement')} style={{
                            position: 'fixed', 
                            top: '0',
                            left: '0',
			                width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(128,128,128,0.8)',
                            zIndex:9999
                            }}>
                        <Toast show={showAIMSAlert} onClose={() => this.setState({ showAIMSAlert: false })} style={{
                                position: 'fixed', 
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 99999,
                                maxWidth: '500px'
                                }} delay={100000} autohide>
                            <Toast.Header className={(aimsAlertHeaderClass)}>
                                <strong className="mr-auto" id="toastheadercontext" style={{width:"300px"}}>{aimsAlertText}</strong>
                            </Toast.Header>
                            <Toast.Body dangerouslySetInnerHTML={{__html: aimsAlertHTML}} style={{textAlign:"left"}}></Toast.Body>
                        </Toast>
                    </div>
                {/* </ToastContainer> */}

                {/* <Modal className='aimsAlertModal' show={showAIMSAlert} onHide={() => this.setState({ showAIMSAlert: false })} backdrop="static" keyboard={false} centered>
                    <Modal.Header className={(aimsAlertHeaderClass)} closeButton>
                        <Modal.Title>
                            <strong className="mr-auto" id="modalalerttitle">{aimsAlertText}</strong>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body dangerouslySetInnerHTML={{__html: aimsAlertHTML}} style={{textAlign:"left"}}></Modal.Body>
                </Modal> */}
                <Modal show={showConfirmationModal} onHide={this.confirmationNo} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Confirmation!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body dangerouslySetInnerHTML={{__html: confirmationModalText}}></Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.confirmationYes}>Yes</Button>
                        <Button variant="secondary" onClick={this.confirmationNo}>No</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}